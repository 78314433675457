exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cv-jsx": () => import("./../../../src/pages/cv.jsx" /* webpackChunkName: "component---src-pages-cv-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-o-jsx": () => import("./../../../src/pages/o.jsx" /* webpackChunkName: "component---src-pages-o-jsx" */),
  "component---src-pages-order-confirmation-jsx": () => import("./../../../src/pages/order/confirmation.jsx" /* webpackChunkName: "component---src-pages-order-confirmation-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-templates-piece-template-jsx": () => import("./../../../src/templates/piece-template.jsx" /* webpackChunkName: "component---src-templates-piece-template-jsx" */)
}

